<template>
  <div class="mt-3 w-100">
    <v-alert v-if="goods.status === 100" color="error" text dense border="left">
      {{ $t('labels.goods_status_stop')}}
    </v-alert>

    <div class="d-flex align-center justify-space-between mb-2">
      <template v-if="!goods.d_size && !goods.r_size && !goods.c_size">
        <div class="w-24">
          <v-text-field v-model.number="goods.new_d_size" class="c-input-xs input-required" type="number"
            :label="$t('labels.d_size')" dense outlined clearable hide-details autofocus ref="inputIndex_1"
            @keyup.enter="nextInputFocus(1)" min="1"></v-text-field>
        </div>
        <div class="w-24">
          <v-text-field v-model.number="goods.new_r_size" class="c-input-xs input-required" type="number"
            :label="$t('labels.r_size')" dense outlined clearable hide-details :disabled="!goods.new_d_size"
            ref="inputIndex_2" @keyup.enter="nextInputFocus(2)" min="1"></v-text-field>
        </div>
        <div class="w-24">
          <v-text-field v-model.number="goods.new_c_size" class="c-input-xs input-required" type="number"
            :label="$t('labels.c_size')" dense outlined clearable hide-details :disabled="!goods.new_r_size"
            ref="inputIndex_3" @keyup.enter="nextInputFocus(3)" min="1"></v-text-field>
        </div>
      </template>
      <div v-else>
        <span class="grey--text">{{ $t('labels.dxrxc_size') }}: </span>{{ goods.d_size }}x{{ goods.r_size }}x{{
          goods.c_size }}
      </div>

      <template v-if="!goods.pre_weight">
        <div class="w-24">
          <v-text-field v-model.number="goods.new_pre_weight" class="c-input-xs input-required" type="number"
            :label="$t('labels.gram')" dense outlined clearable hide-details
            :disabled="!goods.new_c_size && !goods.c_size" ref="inputIndex_4"
            @keyup.enter="() => { nextInputFocus(4); updateGoodsProperties() }" min="1"></v-text-field>
        </div>
      </template>
      <div v-else>
        <span class="grey--text">{{ $t('labels.weight_1') }}: </span>{{ goods.pre_weight }} {{ $t('labels.gram') }}
      </div>
    </div>
    <div class="mb-2">
      <v-alert v-if="goods.comment" text dense color="warning" border="left" style="font-size: 14px">
        <b>{{ $t('labels.note') }}: </b> {{ goods.comment }}
      </v-alert>
      <v-text-field v-else v-model="goods.new_comment" class="c-input-xs" :label="$t('labels.comment')" dense outlined
        clearable hide-details @change="nextInputFocus(5)"></v-text-field>
    </div>

    <div class="text-center mb-2">
      {{ $t('labels.checked') }}:
      <span class="info--text text-h6">{{ item.receipted_quantity }}</span>
      <span class="text-h6">/</span>
      <span class="error--text text-h6">{{ item.request_quantity }}</span>
    </div>

    <div class="d-flex align-center justify-space-between mb-2" v-if="goods.process_exp_date">
      <div class="w-24">
        <v-autocomplete v-model="year" :items="yearOptions" class="c-input-xs" :label="$t('labels.expired_year')" dense
          outlined clearable hide-details ref="inputIndex_6" @change="nextInputFocus(6)"></v-autocomplete>
      </div>
      <div class="w-24">
        <v-autocomplete v-model="month" :items="monthOptions" class="c-input-xs" :label="$t('labels.expired_month')" dense
          outlined clearable hide-details :disabled="!year" ref="inputIndex_7"
          @change="nextInputFocus(7)"></v-autocomplete>
      </div>
      <div class="w-24">
        <v-autocomplete v-model="day" :items="dayOptions" :disabled="!month || !year" class="c-input-xs"
          :label="$t('labels.expired_day')" dense outlined clearable hide-details ref="inputIndex_8"
          @change="nextInputFocus(8)"></v-autocomplete>
      </div>
      <div class="w-24">
        <v-text-field v-model="lotTracking" class="c-input-xs" type="text" :label="$t('labels.lot')" dense outlined
          clearable hide-details ref="inputIndex_9" @keyup.enter="nextInputFocus(9)"></v-text-field>
      </div>
    </div>

    <v-row dense>
      <v-col cols="6">
        <input-qr-scan-model v-model="basketCode" class="c-input-small" type="text"
          :label="$t('labels.basket_code_or_location')" dense outlined clearable hide-details ref="inputIndex_10"
          :disabled="(!goods.new_pre_weight && !goods.weight) || scannedBaskets.length > 0 || scannedLocations.length > 0" @keyupEnter="scanBasket"></input-qr-scan-model>
      </v-col>
      <v-col cols="6">
        <v-text-field class="c-input-xs" type="text" :label="`Sọt: ${scannedBaskets.length} - Vị trí: ${scannedLocations.length}`" dense outlined
          clearable hide-details disabled :value="scannedBasketTxt"></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <v-alert v-if="goods.no_label" color="warning" class="mb-0" text dense border="left"
          style="height: 40px; font-size: 12px;">
          {{ $t('labels.goods_no_label') }}
        </v-alert>
        <template v-else>
          <v-text-field v-if="!isDisabledPrintInput" v-model.number="printNumber" class="c-input-small input-required"
            type="number" :label="$t('labels.print_stamp_max_50')" dense outlined clearable hide-details
            append-icon="mdi-printer" :disabled="isDisabledPrintInput" ref="inputIndex_11" @keyup.enter="printStamp"
            @click:append="printStamp" min="1" max="1000"></v-text-field>
          <v-text-field v-else class="c-input-small" type="number" :value="printNumber"
            :label="$t('labels.print_stamp_max_50')" dense outlined hide-details readonly append-icon="mdi-printer"
            ref="inputIndex_11" @keyup.enter="printStamp" @click:append="printStamp" min="1"></v-text-field>
        </template>
      </v-col>
      <v-col cols="6">
        <v-text-field v-if="goods.status !== 100" v-model.number="receiptQuantity" class="c-input-small input-required"
          type="number" :label="$t('labels.check_1')" dense outlined clearable hide-details min="0" ref="inputIndex_12"
          @keyup.enter="checkAndShowRollUid"></v-text-field>
        <v-text-field v-else value="0" class="c-input-small" type="number" disabled readonly :label="$t('labels.check_1')"
          dense outlined clearable hide-details></v-text-field>
      </v-col>
    </v-row>

    <div class="d-flex align-center justify-space-between">
      <div>
        <v-btn x-small color="success" class="mr-1" @click="confirm" v-if="isShowBtnConfirm">
          {{ $t('labels.confirm') }}
        </v-btn>
        <v-btn x-small color="warning" class="mr-1" @click="confirmNoGoods" v-else>
          {{ $t('labels.no_goods') }}
        </v-btn>
      </div>
      <div>
        <v-btn x-small color="error" @click="cancel">
          {{ $t('labels.cancel') }}
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <ConfirmRemoveBox v-if="dialogConfirmRemove" :title="$t('labels.remove_basket')"
        :description="$t('labels.scan_basket_and_confirm_to_remove')" :label="$t('labels.remove_basket_and_confirm')"
        :placeholder="$t('labels.remove_basket_and_confirm')" :codes="removeCodes" @cancel="cancelConfirmRemove"
        @confirm="confirmRemove" />
    </v-dialog>

    <v-dialog v-model="dialogRollUid" persistent max-width="350px">
      <RollGoodsUid v-if="dialogRollUid" :goods="goods" :insert-tracking="insertTracking"
        :receipt-quantity="receiptQuantity" :print-quantity="printNumber" @cancel="cancelRollUid"
        @confirm="confirmRollUid" />
    </v-dialog>

  </div>
</template>

<script>
import checkNormalGoodsMixins from "@/components/pos_goods/mixins/checkNormalGoodsMixins";

export default {
  name: "CheckNormalGoods",
  components: {
    ConfirmRemoveBox: () => import('@/components/common/ConfirmRemoveBox'),
    RollGoodsUid: () => import('@/components/pos_goods/RollGoodsUid'),
  },
  mixins: [checkNormalGoodsMixins]
}
</script>

<style scoped></style>
